<template>
  <van-tabbar v-model="active">
    <van-tabbar-item replace to="/" name="home" icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item replace to="/article" name="article" icon="records">文章</van-tabbar-item>
    <van-tabbar-item replace to="/photo" name="photo" icon="photo-o">相册</van-tabbar-item>
    <van-tabbar-item replace to="/info" name="info" icon="user-o">动态</van-tabbar-item>
  </van-tabbar>
  <div class="main-view">
    <router-view />
  </div>
</template>

<script>
import {Tabbar, TabbarItem} from 'vant';

export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      active: "home",
    }
  },
  watch: {
    $route(newRoute) {
      this.active = newRoute.name
      if (this.active == "photoWall") this.active = "photo"
      else if (this.active == "articleDetail") this.active = "article"
    }
  },
  methods: {}
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
}

.main-view {
  margin-bottom: 50px;
}
</style>
