<template>
  <div id="wrapper">
    <div id="bg"></div>
    <div id="overlay"></div>
    <div id="main">
      <!-- Header -->
      <div id="header">
        <van-image
            round
            width="25vh"
            height="25vh"
            fit="cover"
            src="https://www.liangtx.top/img/me1.jpg"
            class="my-image"
        />
        <h1>Sinon</h1>
        <p>北京科技大学&nbsp;&bull;&nbsp;信息与通信工程&nbsp;&bull;&nbsp;研究生</p>
        <p class="intro">
          北京科技大学通信研究生一枚，正在学习网站开发的各种知识。这个网站会被用来收集和测试一些好玩的小东西，时不时记录下自己的学习心得，还会将我拍的相片或收藏的图片放进照片墙中，本质上只是自娱自乐的个人网站，能给你留下印象的话不胜荣幸。</p>
        <nav>
          <ul>
            <li>
              <router-link to="/info"><i class="iconfont icon-gengduo"><span>更多</span></i></router-link>
            </li>
            <li><a href="https://leetcode-cn.com/u/liangtx/"><i class="iconfont icon-leetcode"><span>力扣</span></i></a>
            </li>
            <li><i class="iconfont icon-email"><span>邮箱</span></i></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import {skel} from "@/assets/moving-bg/skel.min.js"

export default {
  name: "HomeView",
  data() {
    return {}
  },
  mounted() {
    skel().init({
      reset: 'full'
    });
  }
}
</script>

<style scoped>
@import '@/assets/moving-bg/style.css';
@import '@/assets/moving-bg/style-mobile.css';
@import '@/assets/font/iconfont.css';

.my-image {
  margin: 0 0 0.75rem;
}

.intro {
  text-align: left;
  text-indent: 2em;
}
</style>
