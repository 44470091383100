<template>
  <van-nav-bar
      left-text="返回"
      right-text="目录"
      left-arrow
      fixed
      @click-left="onClickLeft"
      @click-right="onClickRight">
    <template #title>
      <ArticleDrop :data="allData"/>
    </template>
  </van-nav-bar>
  <ArticleDetail :data="data"/>
  <ArticleCatalog ref="catalog"/>
</template>

<script>
import ArticleDetail from "@/components/article/ArticleDetail";
import ArticleCatalog from "@/components/article/ArticleCatalog";
import ArticleDrop from "@/components/article/ArticleDrop";
import {NavBar} from 'vant';
import {ajax} from "@/assets/js/func";
import api from "@/assets/js/api";

export default {
  name: "ArticleDetailView",
  components: {
    ArticleDrop,
    ArticleDetail,
    ArticleCatalog,
    [NavBar.name]: NavBar,
  },
  data() {
    return {
      allData: [],
      data: [],
    }
  },
  created() {
    ajax.get(api.articleList, {"limit": 100, "times": 0}, (res) => {
      this.allData = res.data
    })
    ajax.get(api.article, {"id": this.$route.params.id}, (res) => {
      this.data = JSON.parse(res.data)
    })
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name == "articleDetail") {
        ajax.get(api.article, {"id": newRoute.params.id}, (res) => {
          this.data = JSON.parse(res.data)
        })
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push('/article')
    },
    onClickRight() {
      this.$refs.catalog.show = true
    }
  }
}
</script>

<style scoped>

</style>