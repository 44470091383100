import axios from "axios";
import qs from "qs";

export let ajax = {
    get (url, data, done) {
        axios({
            method: 'get',
            url: url,
            params: data,
        })
            .then(done)
            .catch(err => {
                console.log(err);
            })
    },
    post (url, data, done) {
        axios({
            method: 'post',
            url: url,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data),
        })
            .then(done)
            .catch(err => {
                console.log(err);
            })
    }
}

export let cookie={
    setCookie (form, KeepTime) {
        // 登陆时间
        var loginTime = new Date()
        // 设置保存时间
        loginTime.setTime(loginTime.getTime() + 24 * 60 * 60 * 1000 * KeepTime)
        // 设置过期时间
        var expires = ';expires=' + loginTime.toGMTString()
        // 字符串拼接cookie
        window.document.cookie = 'form=' + form + ';path=/home' + expires
    },
    getCookie () {
        if (document.cookie.length > 0) {
            // 获取cookie数组
            let cArr = document.cookie.split(';')
            cArr.forEach((item, index) => {
                var newArr = item.split('=')
                if (newArr[0] === 'form') {
                    return newArr[1]
                }
            })
        }
    },
    clearCookie () {
        this.setCookie('', '', -1)
    }
}