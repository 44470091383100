<template>
  <div class="article">
    <h2 class="title">{{data.title}}</h2>
    <div class="info">
      <span>浏览次数：{{data.views}}</span>
      <span>发布时间：{{data.create_time}}</span>
    </div>
    <div class="content" v-html="data.content"></div>
  </div>

</template>

<script>
import hljs from "@/assets/hljs/highlight.min.js"
import $ from "jquery"
export default {
  name: "ArticleDetail",
  props: {
    data: {
      title: "",
      content: "",
      views: "",
      create_time: "",
    }
  },
  updated() {
    $('pre').each(function (index,el) {
      let pre_class = el.className;
      let pre_code = el.innerHTML;
      switch (pre_class) {
        case "brush:js;toolbar:false;":
          el.innerHTML = '<code class="language-javascript" style="letter-spacing: 1px">' + pre_code + '</code>';
          break;
        case "brush:html;toolbar:false;":
          el.innerHTML = '<code class="language-html" style="letter-spacing: 1px">' + pre_code + '</code>';
          break;
        case "brush:php;toolbar:false;":
          el.innerHTML = '<code class="language-php" style="letter-spacing: 1px">' + pre_code + '</code>';
          break;
        default:
          break;
      }
    })
    hljs.hljs.highlightAll();
  }
}
</script>

<style scoped>
@import "../../assets/hljs/styles/lioshi.min.css";
.article {
  padding: 1rem;
  margin: 50px 0;
}
.article .title {
  text-align: center;
}
.article .info {
  text-align: center;
  font-size: .8rem;
  padding: .5rem 0;
  color: #9b9b9b;
}
.article .info span {
  padding-right: .5rem;
}
.article .content :deep( img) {
  width: 100%;
  height:auto;
  margin-bottom: .5rem !important;
}
.article .content :deep( p) {
  margin-bottom: .5rem !important;
}
.article .content :deep( ul) {
  width: 100% !important;
}
</style>