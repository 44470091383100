import {createRouter, createWebHistory} from "vue-router";
import HomeView from "@/views/HomeView";
import PhotoView from "@/views/PhotoView"
import InfoView from "@/views/InfoView"
import ArticleView from "@/views/ArticleView"
import ArticleDetailView from "@/views/ArticleDetailView"
import PhotoWall from "@/components/photo/PhotoWall";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {title: 'Sinon的小站', keyword: 'Sinon的小站', description: 'Sinon的小站'},
    },
    {
        path: "/info",
        name: "info",
        component: InfoView,
        meta: {title: '动态'},
    },
    {
        path: "/photo",
        name: "photo",
        component: PhotoView,
        meta: {title: '相册'},
    },
    {
        path: "/photoWall/:id",
        name: "photoWall",
        component: PhotoWall,
        meta: {title: '相片墙'},
    },
    {
        path: "/article",
        name: "article",
        component: ArticleView,
        meta: {title: '文章列表'},
    },
    {
        path: "/articleDetail/:id",
        name: "articleDetail",
        component: ArticleDetailView,
        meta: {title: '文章'},
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
