<template>
  <div class="frame loaded">
    <div class="preview__area" @click="imagePreview"></div>
    <div class="frame__title-wrap">
      <h1 class="frame__title"><span>{{ data.title }}</span><span>|</span>
        <router-link class="frame__tagline" to="/photo">返回列表</router-link>
      </h1>
    </div>
    <div class="frame__links">
      <router-link :to="data.pre?'/photoWall/'+data.pre:''">Previous List</router-link>
      <router-link :to="data.next?'/photoWall/'+data.next:''">Next List</router-link>
    </div>
  </div>
</template>

<script>
import photoClass from "@/assets/photoWall"
import {ajax} from "@/assets/js/func";
import api from "@/assets/js/api";
import $ from 'jquery';
import {ImagePreview} from 'vant';

export default {
  name: "PhotoWall",
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      data: {
        id: "",
        title: "",
        coverimg: "",
        content: "",
        photo: "",
        create_time: "",
        pre: "",
        next: ""
      },
      curPhotoWall: "",
    }
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name == "photoWall") this.loadPhoto()
    }
  },
  mounted() {
    this.loadPhoto()
  },
  methods: {
    loadPhoto() {
      ajax.get(api.photo, {"id": this.$route.params.id}, (res) => {
        this.data = JSON.parse(res.data)
        if (this.curPhotoWall) {
          $(".frame").removeClass('loaded').addClass('loading')
          for (let i = this.curPhotoWall.scene.children.length - 1; i >= 0; i--) {
            this.curPhotoWall.scene.removeChild(this.curPhotoWall.scene.children[i]);
          }
          this.curPhotoWall.mediasImages = this.data.photo
          this.curPhotoWall.createMedias(this.$route.params.id)
          this.curPhotoWall.createBackground()
          this.curPhotoWall.createPreloader()
        } else {
          this.curPhotoWall = new photoClass(this.data.id, this.data.photo)
        }
      })
    },
    imagePreview() {
      let image = this.curPhotoWall.onClick().replace(/_600_600/g, "");
      ImagePreview({
        images: [image],
        showIndex: false,
      });
    }
  },
}
</script>

<style scoped>
@import '@/assets/photoWall/css/main.css';
</style>