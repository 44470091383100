<template>
  <van-grid :column-num="2" :border="borderFlag">
    <van-grid-item v-for="item in data" :key="item.id">
      <van-image
          width="45vw"
          height="45vw"
          radius="10px"
          fit="cover"
          position="center"
          lazy-load
          :src="'https://www.liangtx.top/'+item.coverimg"
          @click="imageClick(item)"
      />
      <span>{{ item.title }}</span>
    </van-grid-item>
  </van-grid>
  <van-action-sheet v-model:show="showFlag">
    <div class="content">
      <h2>{{ showContent.title }}</h2>
      <van-image
          width="80vw"
          height="40vh"
          radius="10px"
          fit="cover"
          position="center"
          lazy-load
          :src="'https://www.liangtx.top/'+showContent.coverimg"
      />
      <p v-html="showContent.content"></p>
      <div class="author"><p>作者：Sinon</p>
        <p>发布时间：{{ showContent.create_time }}</p></div>
      <van-button round type="primary" :to="'/photoWall/'+showContent.id">进入相册</van-button>
    </div>
  </van-action-sheet>
</template>

<script>
import {Grid, GridItem, ActionSheet} from 'vant';

export default {
  name: "PhotoList",
  components: {
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [ActionSheet.name]: ActionSheet,
  },
  props: {
    data: [],
  },
  data() {
    return {
      borderFlag: false,
      showFlag: false,
      showContent: {
        id: '',
        title: '',
        coverimg: '',
        content: '',
        create_time: '',
      },
    }
  },
  methods: {
    imageClick(item) {
      this.showFlag = true
      this.showContent = item
    }
  }
}
</script>

<style scoped>
.content {
  padding: 20px;
  text-align: center;
}

.content .van-image {
  margin: 20px 0;
}

.content p {
  text-align: left;
}

.content .author {
  padding: 20px 0 10px;
}

.content .van-button {
  width: 80vw;
}
</style>