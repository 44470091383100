<template>
  <router-link :to="'/articleDetail/'+data.id" class="article-item">
    <div class="article">
      <h2 style="grid-area: title">{{ data.title }}</h2>
      <van-image
          fit="cover"
          lazy-load
          :src="'https://www.liangtx.top/'+data.coverimg"
          class="image"
      />
      <p class="content" v-html="data.brief"></p>
      <p style="grid-area: visitor">{{ data.views + '浏览' }}</p>
      <p style="grid-area: time">{{ data.create_time }}发布</p>
    </div>
    <div class="divider"></div>
  </router-link>
</template>

<script>
export default {
  name: "ArticleList",
  props: {
    data: {
      id: "",
      title: "",
      content: "",
      brief: "",
      views: "",
      create_time: "",
    }
  }
}
</script>

<style scoped>
.article-item {
  color: #000;
}

.article {
  display: grid;
  grid-auto-columns: 25% 50% 25%;
  grid-template-areas:
        "title title title"
        "content content image"
        "visitor time time";
  padding: 1rem;
  background-color: #fff;
}

.article .image {
  grid-area: image;
  max-height: 63px;
  margin: 1rem 0;
}

.article .content {
  grid-area: content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding: 1rem 1rem 0 0;
  margin-bottom: 1rem;
  max-height: 5.5rem;
}

.divider {
  height: 0.5rem;
  background-color: #efefef;
}
</style>