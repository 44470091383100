<template>
  <div class="col-full">
    <Schedule v-for="item in data" :data="item"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Schedule from "@/components/Schedule";
import {ajax} from "@/assets/js/func.js";
import api from "@/assets/js/api.js";

export default {
  name: "InfoView",
  components: {
    Schedule,
  },
  data() {
    return {
      data: [],
    }
  },
  created() {
    ajax.get(api.schedule,{},(res) => {this.data=res.data})
  },
};
</script>
