<template>
  <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
  >
    <PhotoList :data="data"/>
  </van-list>
</template>

<script>
import PhotoList from "@/components/photo/PhotoList";
import {List} from 'vant';
import {ajax} from "@/assets/js/func";
import api from "@/assets/js/api";

export default {
  name: "PhotoView",
  components: {
    PhotoList,
    [List.name]: List,
  },
  data() {
    return {
      loading: false,
      finished: false,
      limit: 100,
      times: 0,
      data: [],
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    onLoad() {
      ajax.get(api.photoList, {"limit": this.limit, "times": this.times++}, (res) => {
        this.data = this.data.concat(res.data)
        this.loading = false
        if (res.data.length < this.limit) this.finished = true
      })
    }
  }
};
</script>
