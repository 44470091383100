import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {Lazyload, Image as VanImage, Button as VanButton} from 'vant';

if (navigator.userAgent.indexOf("iPhone") != -1 || navigator.userAgent.indexOf("Android") != -1 || navigator.userAgent.indexOf("iPad") != -1 || navigator.userAgent.indexOf("iPod") != -1) {
    createApp(App).use(store).use(router).use(Lazyload).use(VanImage).use(VanButton).mount("#app");
} else {
    window.location.href = "https://www.liangtx.top"
}

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
