<template>
  <li class="_title" :class="my_class"><a href="javascript:void(0);" @click="jump(data.index)">{{ data.text }}</a></li>
</template>

<script>
import $ from "jquery"

export default {
  name: "CatalogItem",
  props: {
    data: {type: 1, index: 1, text: ""},
  },
  data() {
    return {
      my_class: "_title_" + this.data.type,
    }
  },
  methods: {
    jump(id) {
      $("._title.active").removeClass('active')
      this.my_class = this.my_class + " active"
      let scroll_offset = $("#title-" + id).offset()
      let scroll = scroll_offset.top - 50
      $("body,html").animate({
        scrollTop: scroll //让body的scrollTop等于pos的top，就实现了滚动
      }, 300)
      this.$emit('child-event',false)
    }
  }
}
</script>

<style scoped>

</style>