<template>
  <div class="col-1-4 portfolio-box" :class="{active: isActive}" @click="isActive=!isActive">
    <div :class="'portfolio-image image-'+data.id" v-lazy:background-image="'https://www.liangtx.top/'+data.coverimg"
         :key="data.id"></div>
    <div class="portfolio-caption">
      <div class="portfolio-time">
        <div class="portfolio-day">{{ change(data.time, 2) }}</div>
        <div class="portfolio-month">{{ change(data.time, 1) }}</div>
        <div class="portfolio-year">{{ change(data.time, 0) }}</div>
      </div>
      <div class="portfolio-details portfolio-title">{{ data.title }}</div>
      <div class="portfolio-cat" v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Schedule',
  props: {
    data: {
      id: "",
      title: "",
      coverimg: "",
      content: "",
      href: "",
      time: "",
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    change(time, num) {
      if (num == 1) {
        let month = parseInt(time.split('-')[1])
        switch (month) {
          case 1:
            return "January";
            break;
          case 2:
            return "February";
            break;
          case 3:
            return "March";
            break;
          case 4:
            return "April";
            break;
          case 5:
            return "May";
            break;
          case 6:
            return "June";
            break;
          case 7:
            return "July";
            break;
          case 8:
            return "August";
            break;
          case 9:
            return "September";
            break;
          case 10:
            return "October";
            break;
          case 11:
            return "November";
            break;
          case 12:
            return "December";
            break;
          default:
            return "error";
            break;
        }
      } else {
        return time.split('-')[num]
      }
    }
  }
}
</script>

<style scoped>
@import '@/assets/css/schedule.css';
</style>