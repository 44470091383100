import {Renderer, Camera, Transform, Plane} from 'ogl'
import NormalizeWheel from 'normalize-wheel'
import $ from "jquery"
import debounce from 'lodash/debounce'

import {lerp} from './utils/math'

import Media from './Media'
import Background from './Background'

export default class App {
    constructor(id, images) {
        $(".frame").removeClass('loaded').addClass('loading')

        this.scroll = {
            ease: 0.05,
            current: 0,
            target: 0,
            last: 0
        }

        this.onCheckDebounce = debounce(this.onCheck, 200)

        this.mediasImages = images
        for(let i in this.mediasImages){
            let text=this.mediasImages[i].text.split('\n')
            for(let j in text){
                let n=Math.floor(text[j].length/15)
                let arr=text[j].split('')
                while(n>0){
                    arr.splice(15*n,0,'\n')
                    n--
                }
                text[j]=arr.join('')
            }
            this.mediasImages[i].text=text.join('\n')
        }
        this.curIndex=0

        this.createRenderer()
        this.createCamera()
        this.createScene()

        this.onResize()

        this.createGeometry()
        this.createMedias(id)
        this.createBackground()

        this.update()

        this.addEventListeners()

        this.createPreloader()
    }

    createPreloader() {
        Array.from(this.mediasImages).forEach(({image: source}) => {
            const image = new Image()

            this.loaded = 0

            image.src = source
            image.crossOrigin = "anonymous"
            image.onload = _ => {
                this.loaded += 1

                if (this.loaded === this.mediasImages.length) {
                    $(".frame.loading").removeClass('loading').addClass('loaded')
                }
            }
        })
    }

    createRenderer() {
        this.renderer = new Renderer()

        this.gl = this.renderer.gl
        this.gl.clearColor(0.79607843137, 0.79215686274, 0.74117647058, 1)

        // document.body.appendChild(this.gl.canvas)
        $(".frame").prepend($(this.gl.canvas))
    }

    createCamera() {
        this.camera = new Camera(this.gl)
        this.camera.fov = 45
        this.camera.position.z = 20
    }

    createScene() {
        this.scene = new Transform()
    }

    createGeometry() {
        this.planeGeometry = new Plane(this.gl, {
            heightSegments: 50,
            widthSegments: 100
        })
    }

    createMedias(id) {
        this.medias = this.mediasImages.map(({image, text}, index) => {
            const media = new Media({
                geometry: this.planeGeometry,
                gl: this.gl,
                image,
                index,
                length: this.mediasImages.length,
                renderer: this.renderer,
                scene: this.scene,
                screen: this.screen,
                text,
                viewport: this.viewport,
                id
            })

            return media
        })
    }

    createBackground() {
        this.background = new Background({
            gl: this.gl,
            scene: this.scene,
            viewport: this.viewport
        })
    }

    /**
     * Events.
     */
    onTouchDown(event) {
        this.isDown = true

        this.scroll.position = this.scroll.current
        this.start = event.touches ? event.touches[0].clientX : event.clientX
    }

    onTouchMove(event) {
        if (!this.isDown) return

        const x = event.touches ? event.touches[0].clientX : event.clientX
        const distance = (this.start - x) * 0.1

        this.scroll.target = this.scroll.position + distance
    }

    onTouchUp(event) {
        this.isDown = false

        this.onCheck()
    }

    onWheel(event) {
        const normalized = NormalizeWheel(event)
        const speed = normalized.pixelY

        this.scroll.target += speed * 0.02

        this.onCheckDebounce()
    }

    onCheck() {
        const {width} = this.medias[0]
        const itemIndex = Math.round(Math.abs(this.scroll.target) / width)
        const item = width * itemIndex

        if (this.scroll.target < 0) {
            this.scroll.target = -item
        } else {
            this.scroll.target = item
        }
    }

    onClick() {
        const {width} = this.medias[0]
        this.curIndex = Math.round(Math.abs(this.scroll.current) / width)
        return this.mediasImages[this.curIndex].image
    }

    /**
     * Resize.
     */
    onResize() {
        this.screen = {
            height: window.innerHeight,
            width: window.innerWidth
        }

        this.renderer.setSize(this.screen.width, this.screen.height)

        this.camera.perspective({
            aspect: this.gl.canvas.width / this.gl.canvas.height
        })

        const fov = this.camera.fov * (Math.PI / 180)
        const height = 2 * Math.tan(fov / 2) * this.camera.position.z
        const width = height * this.camera.aspect

        this.viewport = {
            height,
            width
        }

        if (this.medias) {
            this.medias.forEach(media => media.onResize({
                screen: this.screen,
                viewport: this.viewport
            }))
        }
    }

    /**
     * Update.
     */
    update() {
        this.scroll.current = lerp(this.scroll.current, this.scroll.target, this.scroll.ease)

        if (this.scroll.current > this.scroll.last) {
            this.direction = 'right'
        } else {
            this.direction = 'left'
        }

        if (this.medias) {
            this.medias.forEach(media => media.update(this.scroll, this.direction))
        }

        if (this.background) {
            this.background.update(this.scroll, this.direction)
        }

        this.renderer.render({
            scene: this.scene,
            camera: this.camera
        })

        this.scroll.last = this.scroll.current

        window.requestAnimationFrame(this.update.bind(this))
    }

    /**
     * Listeners.
     */
    addEventListeners() {
        window.addEventListener('resize', this.onResize.bind(this))

        window.addEventListener('mousewheel', this.onWheel.bind(this))
        window.addEventListener('wheel', this.onWheel.bind(this))

        window.addEventListener('mousedown', this.onTouchDown.bind(this))
        window.addEventListener('mousemove', this.onTouchMove.bind(this))
        window.addEventListener('mouseup', this.onTouchUp.bind(this))

        window.addEventListener('touchstart', this.onTouchDown.bind(this))
        window.addEventListener('touchmove', this.onTouchMove.bind(this))
        window.addEventListener('touchend', this.onTouchUp.bind(this))
    }
}