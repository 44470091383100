<template>
  <van-field
      v-model="value"
      is-link
      readonly
      input-align="center"
      placeholder="选择文章"
      @click="showPicker = true"
  />
  <van-popup v-model:show="showPicker" round position="top">
    <van-picker
        title="文章列表"
        :columns="data"
        :columns-field-names="customFieldName"
        :default-index="index"
        @cancel="showPicker = false"
        @confirm="onConfirm"
    />
  </van-popup>
</template>

<script>
import {Field, Popup, Picker} from 'vant';

export default {
  name: "ArticleDrop",
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
  },
  props: {
    data: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      value: "",
      showPicker: false,
      index: 0,
      customFieldName: {
        text: 'title',
        value: 'id',
      },
    }
  },
  watch: {
    data(newData) {
      let _this = this
      newData.forEach((item, i) => {
        if (item.id == _this.$route.params.id) {
          _this.index = i
          _this.value = item.title
        }
      })
    }
  },
  methods: {
    onConfirm(item) {
      this.value = item.title;
      this.showPicker = false;
      this.$router.push('/articleDetail/' + item.id)
    },
  }
}
</script>

<style scoped>

</style>