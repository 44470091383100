<template>
  <van-popup v-model:show="show"
             round
             position="right"
             closeable
             close-icon="close"
             style="margin-top: 23px">
    <div class="catalog">
      <CatalogItem v-for="item in content" :data="item" @child-event="parentEvent"/>
    </div>
  </van-popup>
</template>

<script>
import {Popup} from 'vant';
import $ from "jquery"
import CatalogItem from "@/components/article/CatalogItem";

export default {
  name: "ArticleCatalog",
  components: {
    CatalogItem,
    [Popup.name]: Popup,
  },
  data() {
    return {
      show: false,
      content: [],
    }
  },
  methods: {
    parentEvent(data) {
      this.show = data
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.content = []
        let _this = this
        $("[class^='utitle']").each(function (index) {
          let text = $(this).text()
          $(this).attr('id', 'title-' + index)
          let type = $(this).attr('class').slice(-1)
          if (type == 1) {
            _this.content.push({type: 1, index: index, text: text})
          } else if (type == 2) {
            _this.content.push({type: 2, index: index, text: text})
          }
        })
      }
    }
  },
}
</script>

<style scoped>
.catalog {
  width: 75vw;
  height: calc(100vh - 46px);
  padding: 20px 10px;
  background: url(https://www.liangtx.top/img/catalogbac1.jpg) 100% 50%/cover no-repeat;
  color: #fff;
}

.catalog :deep( ._title) {
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.catalog :deep( ._title a) {
  color: #fff;
}

.catalog :deep( ._title.active) {
  background-color: grey
}

.catalog :deep( ._title_1) {
  font-size: 1.2rem;
}

.catalog :deep( ._title_2) {
  font-size: 1rem;
  text-indent: 1.2rem;
}
</style>